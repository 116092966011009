import { axiosAuthorized } from "../../api/config";

export const getPackages = async () => {
  try {
    const repsonse = await axiosAuthorized.get(`/gethealthpackages`);
    return repsonse;
  } catch (error) {
    throw error;
  }
}
export const addPackage = async (initialState, health_package_description, general_category, vital_organ_category, test, frequently_booked_test, top_booked_test, package_image, faq, packageId = null) => {
  try {
    const calculatedPrice = packageId ? Math.floor(initialState?.health_package_mrp - (initialState.health_package_mrp * (initialState?.health_package_discount / 100))) : initialState?.health_package_mrp;
    const reqestData = {
      ...initialState,
      health_package_mrp: calculatedPrice,
      health_package_discount: initialState.health_package_discount,
      general_category: general_category ? general_category : null,
      vital_organ_category: vital_organ_category ? vital_organ_category : null,
      health_package_description,
      test,
      frequently_booked_test,
      top_booked_test,
      faq,
      package_image
    }
    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    }
    const url = packageId ? `/healthpackages/update_healthpackages/${packageId}` : `/healthpackages/add_healthpackages`
    const repsonse = await axiosAuthorized[packageId ? "put" : "post"](url, reqestData, requestConfig);
    return repsonse;
  } catch (error) {
    throw error;
  }
}

export const deletePackage = async (packageId) => {
  try {
    const repsonse = await axiosAuthorized.delete(`/healthpackages/delete_healthpackages/${packageId}`);
    return repsonse;
  } catch (error) {
    throw error;
  }
}

export const getPackagesOnlineAppointments = async (mode = 'New') => {
  try {
    let url = `/healthpackagescart/new_healthpackagescart`
    if (mode === "Completed") {
      url = `/healthpackagescart/test_completed`
    }
    const response = await axiosAuthorized.get(url);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updatePackageOnlineAppointmentStatus = async (status, pdf_upload = null, appointmetnId) => {
  try {
    const requestData = pdf_upload ? { status, pdf_upload } : { status };
    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    const response = await axiosAuthorized.put(`/healthpackagescart/update_healthpackagescart/${appointmetnId}`, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}
