import React, { useCallback, useEffect, useState } from 'react';
import { AddButton, ImageInput, NumberInput, RadioInput, SelectInput, ShowImage, TextAreaInput, TextInput } from '../Common/FormComponents';
import { GrFormClose } from 'react-icons/gr';
import { useForm } from '../../hooks';
import { validateTestModalForm } from '../../utils/testsUtils/validateTestForm';
import { addTest, getTests } from '../../utils/testsUtils/testsUtils';
import { getGeneralCategories } from '../../utils/categoriesUtils/generalCategoryUtils';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { toast } from "react-toastify";
import { getVitalOrganCategories } from '../../utils/categoriesUtils/vitalOrganCategoryUtils';
import { IoSearchOutline } from "react-icons/io5";
import JoditEditor, {Jodit} from "jodit-react";
import { useRef } from 'react';

const config = {
  readonly: false,
  placeholder: "Description",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  },
};
const TestModal = ({ setOpen, editData = null, setEditData, setTestsFn, user }) => {
  const testInitialState = {
    test_name: editData?.test_name || "",
    price: editData?.price || "",
    discount: editData?.discount || 0,
    department: editData?.department || "Select Department",
    health_condition: editData?.health_condition || "",
    // category: editData?.category?.category_name || "Select General Category",
    // vital_organ_category:
    //   editData?.vital_organ_category?.vital_organ_category ||
    //   "Select Vital Organ Category",
    service: editData?.service || "Select Service",
    method: editData?.method || "",
    prescription_required: editData?.prescription_required === true ? "required" : "not-required",
    patient_prepration: editData?.patient_prepration || "",
    clinical_utility: editData?.clinical_utility || "",
    test_required: editData?.test_required || "",
    sample: editData?.sample || "",
    age_group: editData?.age_group || "",
    gender: editData?.gender || "Select Gender",
  };
  const editor = useRef(null);
  const [description, setDescription] = useState(editData?.test || "<p></p>");
  const [test_pic, setTestPic] = useState(editData?.test_pic||null);
  const [testFormData, handleTestData,clearForm] = useForm(testInitialState);
  const [generalCategories, setGeneralCategories] = useState([]);
  // const [generalCats, setGeneralCats] = useState([]);
  // const [generalCatId, setGeneralCatId] = useState("");
  const [vitalOrganCategories, setVitalOrganCategories] = useState([]);
  // const [vitalOrganCats, setVitalOrganCats] = useState([]);
  // const [vitalOrganCatId, setVitalOrganCatId] = useState("");
  const [category, setCategory] = useState(editData?.category || []);
  const [vital_organ_category, setVitalOrganCategory] = useState(editData?.vital_organ_category || []);
  // useEffect(() => {
  //   getGeneralCategories()
  //     .then((res) => {
  //       setGeneralCategories([
  //         { category_name: "Select General Category", _id: "" },
  //         ...res.data.data,
  //       ]);
  //       setGeneralCats([
  //         "Select General Category",
  //         ...res.data.data.map((item) => item.category_name),
  //       ]);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       setGeneralCategories([{ category_name: "Select General Category", _id: "" }]);
  //     });
  //     getVitalOrganCategories()
  //     .then((res) => {
  //       setVitalOrganCategories([
  //         { vital_category_name: "Select Vital Organ Category", _id: "" },
  //         ...res.data.data,
  //       ]);
  //       setVitalOrganCats([
  //         "Select Vital Organ Category",
  //         ...res.data.data.map((item) => item.vital_category_name),
  //       ]);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       setVitalOrganCategories([{ vital_category_name: "Select Vital Organ Category", _id: "" }]);
  //     });
  //   }, []);
  //   useEffect(() => {
  //     const foundCategory = generalCategories.find(
  //       (item) => item.category_name === testFormData.category
  //     );
  //     if (foundCategory) {
  //       setGeneralCatId(foundCategory._id);
  //   }
  // }, [generalCategories, testFormData.category]);
  // useEffect(() => {
  //   const foundCategory = vitalOrganCategories.find(
  //     (item) => item.vital_category_name === testFormData.vital_organ_category
  //   );
  //   if (foundCategory) {
  //     setVitalOrganCatId(foundCategory._id);
  //   }
  // }, [vitalOrganCategories, testFormData.vital_organ_category]);
  const [tests, setTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState(null);
  const setFrequentlyBookedTestsFn = useCallback(() => {
    getTests()
      .then((res) => setTests(res.data.data))
      .catch((error) => console.error(error));
  }, []);
  useEffect(() => {
    setFrequentlyBookedTestsFn();
  }, [setFrequentlyBookedTestsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = tests?.filter((test) => {
      const nameMatch = test?.test_name
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      const idMatch = test?.test_id
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      return nameMatch || idMatch;
    });
    setFilteredData(filteredData);
  }, [tests, searchQuery]);
  const [frequently_booked_test, setTestsData] = useState(
    editData?.frequently_booked_test || []
  );
  const handleFrequentlyBookedTestData = (action, idx = 0, item = null) => {
    if (action === "add") {
      if (!item) return;
      const exist = frequently_booked_test.find(
        (data) => data?._id === item?._id
      );
      if (exist) return;
      setSelectedTest(item);
      setTestsData([...frequently_booked_test, item]);
    } else if (action === "remove") {
      setTestsData(frequently_booked_test.filter((_, index) => index !== idx));
    }
  };
  
  const [selected, setSelected] = useState(null);  
  const setCategoriesFn = useCallback(() => {
      getGeneralCategories()
        .then((res) => {
          setGeneralCategories(res?.data?.data || []);
        })
        .catch((err) => { console.error(err); });
      getVitalOrganCategories()
        .then((res) => {
          setVitalOrganCategories(res?.data?.data);
        })
        .catch((err) => { console.error(err); });
    }, []);
    useEffect(() => {
      setCategoriesFn();
    }, [setCategoriesFn]);
    const [generalCategorySearchQuery, setGeneralCategorySearchQuery] = useState("");
    const [filteredGeneralCategories, setFilteredGeneralCategories] = useState([]);
    useEffect(() => {
      const filteredData = generalCategories?.filter((category) => {
        const nameMatch = category?.category_name
          ?.toLowerCase()
          ?.includes(generalCategorySearchQuery.toLowerCase());
        return nameMatch;
      });
      setFilteredGeneralCategories(filteredData);
    }, [generalCategories, generalCategorySearchQuery]);
    const [vitaOrganCategorySearchQuery, setvitaOrganCategorySearchQuery] = useState("");
    const [filteredVitalOrganCategories, setFilteredVitalOrganCategories] = useState([]);
    useEffect(() => {
      const filteredData = vitalOrganCategories?.filter((category) => {
        const nameMatch = category?.vital_category_name
          ?.toLowerCase()
          ?.includes(vitaOrganCategorySearchQuery.toLowerCase());
        return nameMatch;
      });
      setFilteredVitalOrganCategories(filteredData);
    }, [vitalOrganCategories, vitaOrganCategorySearchQuery]);
  const handleGeneralCategories = (action, idx = 0, item = null) => {
    if (action === "add") {
      if (!item) return;
      const exist = category.find((data) => data?._id === item?._id);
      if (exist) return;
      setSelected(item);
      setCategory([...category, item]);
    } else if (action === "remove") {
      setCategory(category.filter((_, index) => index !== idx));
    }
  };
  const handleVitalOrganCategories = (action, idx = 0, item = null) => {
    if (action === "add") {
      if (!item) return;
      const exist = vital_organ_category.find((data) => data?._id === item?._id);
      if (exist) return;
      setSelected(item);
      setVitalOrganCategory([...vital_organ_category, item]);
    } else if (action === "remove") {
      setVitalOrganCategory(vital_organ_category.filter((_, index) => index !== idx));
    }
  };
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });
  const faqInitialState = { faq_question: "", faq_answer: "" };
  const [faqFormData, handleFaqFromData, clearFaqForm] = useForm(faqInitialState);
  const [faq, setFaqs] = useState(editData?.faq || []);
  const handleFaqs = (action, idx = 0) => {
    if (action === "add") {
      if (
        !faqFormData.faq_question.trim().length ||
        !faqFormData.faq_answer.trim().length
      ) {
        return setErr({ global: "Please Enter Question & Answer" });
      } else {
        setFaqs([
          ...faq,
          {
            faq_question: faqFormData.faq_question,
            faq_answer: faqFormData.faq_answer,
          },
        ]);
        clearFaqForm();
      }
    } else if (action === "remove") {
      setFaqs(faq.filter((_, index) => index !== idx));
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateTestModalForm(testFormData,test_pic, setErr)) {
      setLoading(true);
      addTest(testFormData, description, test_pic, category, vital_organ_category,frequently_booked_test, faq, user?._id, editData?._id)
        .then((res) => {
          setTestsFn();
          setEditData(null);
          setTestPic(null);
          clearForm();
          setOpen(false);
          toast(`Test ${editData ? `Edited` : `Added`}`, {
            type: "success",
            autoClose: 3000,
          });
        })
        .catch((err) => {
          console.log(err); 
          toast(`Test ${editData ? `Edit Failed` : `Add Failed`}`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => setLoading(false));
    }
  }
  return (
    <div className="absolute flex w-full h-screen top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex w-full max-w-screen-md overflow-y-auto h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{editData ? "Edit Test" : "Add New Test"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex flex-col md:flex-row w-full h-fit justify-between gap-5">
          <TextInput
            name="Test Name"
            formName={"test_name"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
          {/* <SelectInput
            data={testFormData}
            handleInput={handleTestData}
            items={generalCats}
            name={"General Category"}
            formName={"category"}
            setError={setErr}
          />
          <SelectInput
            data={testFormData}
            handleInput={handleTestData}
            items={vitalOrganCats}
            name={"Vital Organ Category"}
            formName={"vital_organ_category"}
            setError={setErr}
          /> */}
        </div>
        {category?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {category?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <div className="flex items-center gap-1 text-white">
                  <div className={`flex w-7 h-7`}>
                    <img
                      src={
                        item?.category_icon instanceof File
                          ? URL.createObjectURL(item?.category_icon)
                          : item?.category_icon
                      }
                      alt={"service_image"}
                      className={`rounded-full ${
                        item?.category_icon ? "flex" : "hidden"
                      }`}
                    />
                  </div>
                  <span>{item?.category_name}</span>
                </div>
                <button
                  onClick={() => {
                    handleGeneralCategories("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="relative flex flex-col gap-0.5">
          <span className="absolute inset-y-0 left-0 top-5 flex items-center pl-2">
            <IoSearchOutline fontSize={23} />
          </span>
          <label
            htmlFor={"List Of General Categories"}
            className="text-[14px] text-shadow-black text-white"
          >
            {"List Of General Categories"}
          </label>
          <input
            type="text"
            value={generalCategorySearchQuery}
            onChange={(e) => {
              setGeneralCategorySearchQuery(e.target.value);
            }}
            className="appearance-none rounded-md w-full bg-white border border-gray-300 py-2 pl-10 pr-4 text-gray-700 leading-tight focus:outline-none focus:border-primary"
            placeholder="Search By Name"
          />
        </div>
        <div className="flex gap-5 flex-wrap min-h-[100px] max-h-[100px] overflow-y-scroll px-5 m-3 ">
          {filteredGeneralCategories.length > 0 ? (
            filteredGeneralCategories.map((item) => (
              <div
                key={item._id}
                onClick={() => {
                  handleGeneralCategories("add", null, item);
                }}
                className={`flex px-2 py-1 cursor-pointer w-fit h-fit  border rounded-md ${
                  selected?._id === item?._id
                    ? `bg-white text-primary border-primary`
                    : `text-white`
                }`}
              >
                {`${item?.category_name}`}
              </div>
            ))
          ) : (
            <div className="flex h-full w-full justify-center items-center text-white">
              No Categories Found!
            </div>
          )}
        </div>
        {vital_organ_category?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {vital_organ_category?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <div className="flex items-center gap-1 text-white">
                  <div className={`flex w-7 h-7`}>
                    <img
                      src={
                        item?.vital_category_icon instanceof File
                          ? URL.createObjectURL(item?.vital_category_icon)
                          : item?.vital_category_icon
                      }
                      alt={"service_image"}
                      className={`rounded-full ${
                        item?.vital_category_icon ? "flex" : "hidden"
                      }`}
                    />
                  </div>
                  <span>{item?.vital_category_name}</span>
                </div>
                <button
                  onClick={() => {
                    handleVitalOrganCategories("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="relative flex flex-col gap-0.5">
          <span className="absolute inset-y-0 left-0 top-5 flex items-center pl-2">
            <IoSearchOutline fontSize={23} />
          </span>
          <label
            htmlFor={"List Of Vital Organ Categories"}
            className="text-[14px] text-shadow-black text-white"
          >
            {"List Of Vital Organ Categories"}
          </label>
          <input
            type="text"
            value={vitaOrganCategorySearchQuery}
            onChange={(e) => {
              setvitaOrganCategorySearchQuery(e.target.value);
            }}
            className="appearance-none rounded-md w-full bg-white border border-gray-300 py-2 pl-10 pr-4 text-gray-700 leading-tight focus:outline-none focus:border-primary"
            placeholder="Search By Name"
          />
        </div>
        <div className="flex gap-5 flex-wrap min-h-[100px] max-h-[100px] overflow-y-scroll px-5 m-3 ">
          {filteredVitalOrganCategories.length > 0 ? (
            filteredVitalOrganCategories.map((item) => (
              <div
                key={item._id}
                onClick={() => {
                  handleVitalOrganCategories("add", null, item);
                }}
                className={`flex px-2 py-1 cursor-pointer w-fit h-fit  border rounded-md ${
                  selected?._id === item?._id
                    ? `bg-white text-primary border-primary`
                    : `text-white`
                }`}
              >
                {`${item?.vital_category_name}`}
              </div>
            ))
          ) : (
            <div className="flex h-full w-full justify-center items-center text-white">
              No Categories Found!
            </div>
          )}
        </div>
        {frequently_booked_test?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {frequently_booked_test?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <div className="flex items-center gap-1 text-white">
                  <div className={`flex w-7 h-7`}>
                    <img
                      src={
                        item?.test_pic instanceof File
                          ? URL.createObjectURL(item?.test_pic)
                          : item?.test_pic
                      }
                      alt={"service_image"}
                      className={`rounded-full ${
                        item?.test_pic ? "flex" : "hidden"
                      }`}
                    />
                  </div>
                  <span>{item?.test_name}</span>
                </div>
                <button
                  onClick={() => {
                    handleFrequentlyBookedTestData("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="relative flex flex-col gap-0.5">
          <span className="absolute inset-y-0 left-0 top-5 flex items-center pl-2">
            <IoSearchOutline fontSize={23} />
          </span>
          <label
            htmlFor={"List Of Tests"}
            className="text-[14px] text-shadow-black text-white"
          >
            {"List Of Tests For Booked Together"}
          </label>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            className="appearance-none rounded-md w-full bg-white border border-gray-300 py-2 pl-10 pr-4 text-gray-700 leading-tight focus:outline-none focus:border-primary"
            placeholder="Search By Name, ID"
          />
        </div>
        <div className="flex gap-5 flex-wrap min-h-[150px] max-h-[200px] overflow-y-scroll px-5 m-3 ">
          {filteredData?.length > 0 ? (
            filteredData
              ?.filter((item) => editData?._id !== item?._id)
              ?.map((item) => (
                <div
                  key={item._id}
                  onClick={() => {
                    handleFrequentlyBookedTestData("add", null, item);
                  }}
                  className={`flex px-2 py-1 cursor-pointer w-fit h-fit  border rounded-md ${
                    selectedTest?._id === item?._id
                      ? `bg-white text-primary border-primary`
                      : `text-white`
                  }`}
                >
                  {`${item?.test_name} - ${item?.test_id}`}
                </div>
              ))
          ) : (
            <div className="flex h-full w-full justify-center items-center text-white">
              No Tests Found!
            </div>
          )}
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <SelectInput
            name="Service"
            formName={"service"}
            data={testFormData}
            handleInput={handleTestData}
            items={["Select Service", "lab/home", "lab", "home"]}
            setError={setErr}
          />
          <SelectInput
            name="Department"
            formName={"department"}
            data={testFormData}
            handleInput={handleTestData}
            items={[
              "Select Department",
              "Clinical Biochemistry",
              "Clinical Pathology",
              "Serology",
              "Radiology",
            ]}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <SelectInput
            name="Gender"
            formName={"gender"}
            data={testFormData}
            handleInput={handleTestData}
            items={["Select Gender", "Male", "Female", "Both"]}
            setError={setErr}
          />
          <NumberInput
            name="Price"
            min={0}
            formName={"price"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
          <NumberInput
            max={100}
            min={0}
            name="Discount"
            formName={"discount"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            hide={true}
            name="Sample"
            formName={"sample"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
          <TextInput
            hide={true}
            name="Age Group"
            formName={"age_group"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
        </div>
        <div className="flex flex-col w-full gap-0.5">
          <label htmlFor={"Description"} className="label">
            {"Description"} <span className="text-red-600">*</span>
          </label>
          <div className="bg-white text-black rounded-md">
            <JoditEditor
              ref={editor}
              value={description}
              config={config}
              tabIndex={1}
              onBlur={(newContent) => {
                setDescription(
                  newContent.replace(
                    /<td(?:[^>]*)style="[^"]*"(?:[^>]*)>/g,
                    '<td style="border: 1px solid black;" >'
                  )
                );
              }}
            />
          </div>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextAreaInput
            name={"Method"}
            formName={"method"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 flex-col">
          <TextAreaInput
            name={"Test Required For"}
            formName={"test_required"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
          <TextAreaInput
            name={"Clinical Utility"}
            formName={"clinical_utility"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 flex-col">
          <TextAreaInput
            name={"Patient Prepration"}
            formName={"patient_prepration"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
          <TextAreaInput
            name={"Health Condition"}
            formName={"health_condition"}
            data={testFormData}
            handleInput={handleTestData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <RadioInput
            data={testFormData}
            name="Prescription Type"
            formName={"prescription_required"}
            handleInput={handleTestData}
            setError={setErr}
            options={[
              { label: "Required", value: "required" },
              { label: "Not Required", value: "not-required" },
            ]}
            hide={true}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <ShowImage image={test_pic} setImage={setTestPic} />
          <ImageInput imageName={"Test Image"} setImage={setTestPic} />
        </div>
        {faq?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {faq?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs text-white"
                key={idx}
              >
                <span>{item?.faq_question}</span>
                <button
                  onClick={() => {
                    handleFaqs("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="flex flex-col w-full gap-5">
          <TextInput
            hide={true}
            data={faqFormData}
            handleInput={handleFaqFromData}
            name={"Question"}
            formName={"faq_question"}
            setError={setErr}
          />
          <div className="flex">
            <TextAreaInput
              data={faqFormData}
              handleInput={handleFaqFromData}
              name={"Answer"}
              formName={"faq_answer"}
              setError={setErr}
            />
            <AddButton handleAdd={handleFaqs} />
          </div>
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestModal