import React, { useEffect, useMemo, useState } from "react";
import { NoDataFound, SearchInput } from "../Common";
import { MdOutlineDeleteOutline } from "react-icons/md";
import Table from "../Common/Table/Table";
import { deleteAppointment } from "../../utils/appointmentUtils/appointmentUtils";
import TestStatus from "./TestStatus";

const HospitalAppiontments = ({
  appointments,
  setAppointmentsFn,
  setEditData,
  setOpenTracker,
}) => {
  const headings = useMemo(
    () => [
      "Name",
      "P-ID",
      "V-ID",
      "Phone",
      "Email",
      "Date",
      "Time",
      "BookeTime",
      "Tests",
      "Packages",
      "Price",
      "Status",
      "Action",
      "Delete",
    ],
    []
  );
  const delAppointment = (appointmentId) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteAppointment(appointmentId)
      .then((res) => setAppointmentsFn())
      .catch((err) => console.log(err));
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = appointments?.filter((data) => {
      const nameMatch = data?.user_id?.name
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const pIdMatch = data?.user_id?.patient_id
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const vIdMatch = data?.visit_id
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const numberMatch = data?.user_id?.phone_number
        .toString()
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const emailMatch = data?.user_id?.email_id
        .toString()
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const diseaseMatch = data?.disease
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      return (
        nameMatch ||
        numberMatch ||
        emailMatch ||
        pIdMatch ||
        diseaseMatch ||
        vIdMatch
      );
    });
    setFilteredData(filteredData);
  }, [appointments, searchQuery]);
    useEffect(() => {
      const intervalId = setInterval(() => {
        setAppointmentsFn();
      }, 5 * 60 * 1000);
      return () => clearInterval(intervalId);
    }, [setAppointmentsFn]);
  return (
    <div className="h-full  w-full mt-3">
      <div className="flex w-full justify-center items-center mb-5">
        <div className="flex w-full sm:w-1/2">
          <SearchInput
            placeholder={"Search"}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
      </div>
      {filteredData?.length > 0 ? (
        <Table headings={headings}>
          {filteredData?.map((data, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {data?.user_id?.name}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {data?.user_id?.patient_id}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">{data?.visit_id}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">
                  {data?.user_id?.phone_number}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {data?.user_id?.email_id}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">{data?.date}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">{data?.time}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {data?.createdAt
                    ? new Date(data.createdAt).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                        timeZone: "UTC",
                      })
                    : "N/A"}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize flex flex-col max-h-[100px] overflow-y-auto">
                  {data?.lab_test?.length > 0 ? (
                    data?.lab_test?.map((item, idx) => (
                      <span key={idx}>
                        {" "}
                        {idx + 1} {". "} {item?.test_name}
                      </span>
                    ))
                  ) : (
                    <span>No Tests !</span>
                  )}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize flex flex-col max-h-[100px] overflow-y-auto">
                  {data?.health_packages?.length > 0 ? (
                    data?.health_packages?.map((item, idx) => (
                      <span key={idx}>
                        {" "}
                        {idx + 1} {". "} {item?.health_package_name}
                      </span>
                    ))
                  ) : (
                    <span>No Packages !</span>
                  )}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">{data?.price}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <TestStatus data={data} />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  className="flex justify-center items-center gap-2 text-xs border rounded-md bg-blue-600 text-white p-2"
                  onClick={() => {
                    setEditData(data);
                    setOpenTracker(true);
                  }}
                >
                  Update
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div
                  className="text-sm text-gray-900 capitalize cursor-pointer"
                  onClick={() => {
                    delAppointment(data?._id);
                  }}
                >
                  <MdOutlineDeleteOutline color="red" />
                </div>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <NoDataFound data={"appointments"} />
      )}
    </div>
  );
};

export default HospitalAppiontments;
